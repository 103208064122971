<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Información del punto de venta" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: $store.state.pos.retrieveLoader, text: instance.custom_id ? instance.custom_id : 'Información del pos' }" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn v-if="$store?.state?.auth?.account?.country === 'PE'" color="blue-500" :to="{ name: 'LogsList', query: { pos: instance.id } }"><v-icon left>mdi-alert-circle</v-icon>Ver LOGS</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <VSkeleton v-if="$store.state.branches.retrieveLoader || $store.state.pos.retrieveLoader" />
    <template v-else>
      <v-col class="px-0" style="z-index: 0;">
        <div class="d-flex">
          <!-- detail card -->
          <div class="mr-6" style="min-width: 400px;">
            <v-card class="pa-0" width="400" transition="slide-y-transition" min-height="calc(100% - 150px)" flat style="position:fixed!important;">
              <VCardMapDetail :carouselList="['', instance.photo]" :itemList="cardMapDetail" @handleModal="handleEditConfigPos" :hiddenEdit="true" />
            </v-card>
          </div>
          <!-- detail card -->

          <!-- panels -->
          <div class="pb-5" style="flex:1; min-width: 180px;">
            <!-- statistics -->
            <v-card flat>
              <v-card-text class="pa-0">
                <v-row justify="center" no-gutters>
                  <!-- status -->
                  <v-col cols="4" class="text-center px-0 py-10">
                    <span class="font-weight-bold subtitle-2 grey-500--text">Estado</span>
                    <v-sheet class="d-flex align-center justify-center transparent mt-8">
                      <div>
                        <v-chip class="font-weight-semibold" color="blue-100" small label>Activo<v-icon right size="18">mdi-cloud</v-icon></v-chip>
                        <span class="mt-2 d-block grey-700--text body-1">{{editable.created | dateTime}}</span>
                      </div>
                    </v-sheet>
                  </v-col>
                  <!-- end status -->
                  <v-divider vertical />
                  <!-- documents issued -->
                  <v-col cols="4" class="text-center py-10 pa-0">
                    <span class="font-weight-bold subtitle-2 grey-500--text mx-auto">Cantidad de DTEs</span>
                    <div class="mt-9">
                      <span class="d-block grey-700--text subtitle-2 font-weight-semibold">13.267</span>
                      <span class="d-block grey-500--text subtitle-2 mt-2">Hoy</span>
                    </div>
                  </v-col>
                  <!-- end documents issued -->
                  <v-divider vertical />
                  <!-- documents issued -->
                  <v-col cols="4" class="text-center py-10 px-0">
                    <span class="font-weight-bold subtitle-2 grey-500--text mx-auto">Total vendido</span>
                    <div class="mt-9">
                      <span class="d-block grey-700--text subtitle-2 font-weight-semibold">$757.953 <span class="grey-300--text caption">{{$t('generals.CLP')}}</span></span>
                      <span class="d-block grey-500--text subtitle-2 mt-2">Hoy</span>
                    </div>
                  </v-col>
                  <!-- end documents issued -->
                </v-row>
                <!-- show more statistics -->
                <template v-if="showMoreStatistics">
                  <v-divider />
                  <v-row class="ma-0" justify="center" no-gutters>
                    <!-- documents issued -->
                    <v-col cols="4" class="text-center py-10">
                      <span class="font-weight-bold subtitle-2 grey-500--text mx-auto">Ticket promedio</span>
                      <v-sheet height="100" min-width="100" class="d-flex align-center justify-center transparent">
                        <div>
                          <span class="d-block mt-6 grey-700--text subtitle-2 font-weight-semibold">$45.990</span>
                          <span class="d-block grey-500--text subtitle-2 mt-3">Últimos 7 días</span>
                        </div>
                      </v-sheet>
                    </v-col>
                    <!-- end documents issued -->
                    <v-divider vertical />
                    <!-- average ticket -->
                    <v-col cols="4" class="text-center py-10">
                      <span class="font-weight-bold subtitle-2 grey-500--text mx-auto">Cantidad de DTEs</span>
                      <v-sheet height="100" min-width="100" class="d-flex align-center justify-center transparent">
                        <div>
                          <span class="d-block mt-6 grey-700--text subtitle-2 font-weight-semibold">145.736</span>
                          <span class="d-block grey-500--text subtitle-2 mt-3">Últimos 7 días</span>
                        </div>
                      </v-sheet>
                    </v-col>
                    <!-- end average ticket -->
                    <v-divider vertical />
                    <!-- total sold -->
                    <v-col cols="4" class="text-center py-10">
                      <span class="font-weight-bold subtitle-2 grey-500--text mx-auto">Total vendido</span>
                      <v-sheet height="100" min-width="100" class="d-flex align-center justify-center transparent">
                        <div>
                          <span class="d-block mt-6 grey-700--text subtitle-2 font-weight-semibold">$ 5.305.671<span class="grey-300--text caption">{{$t('generals.CLP')}}</span></span>
                          <span class="d-block grey-500--text subtitle-2 mt-3">Últimos 7 días</span>
                        </div>
                      </v-sheet>
                    </v-col>
                    <!-- end total sold -->
                  </v-row>
                </template>
                <!-- end show more statistics -->
              </v-card-text>
              <v-divider />
              <v-card-actions class="pa-0">
                <v-col class="text-center py-2">
                  <v-btn @click="showMoreStatistics=!showMoreStatistics" outlined :ripple="false">{{ !showMoreStatistics ? 'Ver más estadísticas' : 'Ver menos estadísticas' }} </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
            <!-- end statistics -->

            <!-- pos -->
            <v-expansion-panels class="mt-5" v-model="panelPos">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-5 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col class="pa-0">
                      <span class="ml-n1 subtitle-2 grey-500--text font-weight-bold"><v-icon size="18" left color="grey-500">mdi-printer-pos</v-icon>Configuración POS</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-5">
                  <v-list class="transparent v-list-form px-0">
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Última señal de vida</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.created | dateTime}} <!-- de momento --></v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Instalado el</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.created | dateTime}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Versión de {{env === 'ax' ? 'AX' : 'FC'}}-POS</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">v0.9</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Versión del filtro {{env === 'ax' ? 'AX' : 'FC'}}-POS</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">
                        v0.1
                        <v-btn class="ml-2 mt-n1 body-1" color="blue-500" text :ripple="false">Actualizar<v-icon color="blue-500" right>mdi-autorenew</v-icon></v-btn>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">HMAC</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">
                        <v-tooltip color="white-0" right transition="scale-transition">
                          <template v-slot:activator="{on}">
                            <v-btn @click="toClipboard(instance.hmac || '')" text color="grey-500" v-on="on" :ripple="false" style="justify-content: left;">{{instance.hmac || ''}}</v-btn>
                          </template>
                          <span class="d-block px-3 py-2">Copiar</span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Modo de asignación de {{$t('generals.folios')}}</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">Dinámico</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Modo de almacenamiento</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.store_scheme || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Copias PDF</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.pdf_copies || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Forma de envío</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.sending_way || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Ruta de almacenamiento XML</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.store_path || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Ruta de almacenamiento PDF</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.irs_store_path || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end pos -->

            <!-- folios -->
            <v-expansion-panels v-if="$store.state.auth.account.country === 'CL'" class="mt-5" v-model="panelFolios">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-4 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col>
                      <span class="subtitle-2 grey-500--text font-weight-bold text-capitalize"><v-icon size="18" left color="grey-500">mdi-file-document</v-icon>{{$t('generals.folios')}}</span>
                      <!-- <span class="body-1 grey-500--text font-weight-bold text-capitalize">{{$t('generals.folios')}}
                        <v-tooltip color="white-0" right max-width="280px">
                          <template v-slot:activator="{on}">
                          <v-chip class="ml-2 pl-1 pr-2" v-on="on" color="blue-500" x-small>Demostración<v-icon right x-small>mdi-information-outline</v-icon></v-chip>
                          </template>
                          <span class="d-block pa-2">De momento no muestra la información de tu cuenta. Sin embargo, pronto podrás disfrutar de todos los beneficios.</span>
                        </v-tooltip>
                      </span> -->
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider />
                  <v-empty-state class="mt-n2 pb-2" v-if="!currentList.length" type="documents" id="documentos" customClass="mt-n5" :isFree="true" :title="`Aún no existen ${$t('generals.folios')}`" :height="199" />
                  <v-sheet class="text-center pa-0 mt-n1" rounded v-else>
                    <v-tabs class="mt-n1" color="blue-500" grow height="46" slider-size="3">
                      <v-tab class="body-2 grey-500--text basic-tab" @click="activeFolio=true" exact :ripple="false">Activos</v-tab>
                      <v-tab class="body-2 grey-500--text basic-tab" @click="activeFolio=false" exact :ripple="false">Usados</v-tab>
                    </v-tabs>
                    <v-data-table class="table-not-rounded" :headers="currentHeader" :items="currentList" hide-default-header disable-sort hide-default-footer>
                      <!-- header -->
                      <template v-slot:header="{ props: { headers } }">
                        <VTableHeaders :headers="headers" :sortable="false" />
                        <v-divider style="min-width: 100%; position: absolute;  margin-top: -6px; margin-left: -5px;" />
                      </template>
                      <!-- end header -->
                    </v-data-table>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end folios -->

            <!-- documents -->
            <v-expansion-panels class="mt-5" v-model="panelDocuments">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-4 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col>
                      <span class="subtitle-2 grey-500--text font-weight-bold"><v-icon size="18" left color="grey-500">mdi-file-document-multiple</v-icon>Documentos emitidos</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider v-if="!loading" />
                  <documents-list :loading="loading" :show-pagination="false" />
                  <template v-if="count && !loading">
                    <v-divider />
                    <v-row class="px-4 py-4" align="center">
                      <v-btn class="ml-5 body-1" :to="{name: 'DocumentsList', query: { posdocument__pos__id: instance.id, name: instance.name || instance.custom_id } }" :ripple="false" text color="blue-500">Ver todos los documentos</v-btn>
                    </v-row>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end documents -->
          </div>
          <!-- end panels -->
        </div>
      </v-col>
    </template>
    <!-- dialog edit config Pos -->
    <v-dialog v-model="dialogConfigPos" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Editar configuración POS</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfigPos=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="v-list-form py-6 transparent">
            <v-list-item class="py-1">
              <v-list-item-title>Modo de asignación de {{$t('generals.folios')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-radio-group class="mx-0 mt-n4" v-model="row" row absolute style="position:absolute" dense hide-details>
                  <v-radio label="Lineal" value="radio-1"></v-radio>
                  <v-radio label="Dinámico" value="radio-2"></v-radio>
                </v-radio-group>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Modo de almacenamiento</v-list-item-title>
              <v-list-item-subtitle>
                <v-radio-group class="mx-0 mt-n4" v-model="$v.editable.store_scheme.$mode" row absolute style="position:absolute" dense hide-details>
                  <v-radio label="Base de datos" value="radio-1"></v-radio>
                  <v-radio label="Directorio" value="radio-2"></v-radio>
                </v-radio-group>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Copias PDF</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-select v-model="$v.editable.pdf_copies.$model" :error="$v.editable.pdf_copies.$error" :items="pdfcopiesList" item-text="name" item-value="id" single-line hide-details dense height="30" outlined />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-title>Forma de envío</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-radio-group class="mx-0 mt-n4" v-model="editable.sending_way" row absolute style="position:absolute" dense hide-details>
                  <v-radio label="XML" value="radio-1"></v-radio>
                  <v-radio label="ZIP" value="radio-2"></v-radio>
                </v-radio-group>
                <!-- <v-col cols="8">
                  <v-select v-model="$v.editable.sending_way.$model" :error="$v.editable.sending_way.$error" :items="sendingWayList" item-text="name" item-value="id" single-line hide-details dense height="30" outlined />
                </v-col> -->
              </v-list-item-subtitle>
            </v-list-item>
              <v-list-item>
            <v-list-item-title>Ruta de almacenamiento XML</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model.trim="$v.editable.store_path.$model" :error="$v.editable.store_path.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Ruta de almacenamiento PDF</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model.trim="$v.editable.irs_store_path.$model" :error="$v.editable.irs_store_path.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogConfigPos=false">Cancelar</v-btn>
          <v-btn color="blue-500" @click="setConfigPos">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit config Pos -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength } from 'vuelidate/lib/validators'
import DocumentsList from '@/modules/documents/components/DocumentsList'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ChartDoughnut from '@/components/charts/ChartDoughnut'
import ChartLine from '@/components/charts/ChartLine'
import VCardMapDetail from "@/modules/pos/pos/components/complements/VCardMapDetail"
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    VEmptyState,
    MiniHeader,
    DocumentsList,
    ChartDoughnut,
    ChartLine,
    VCardMapDetail,
    VTableHeaders,
    VSkeleton,
    SkeletonList
  },
  data: () => ({
    scroll: 0,
    loadingPos: false,
    backupEditableBasicInfoPos: {},
    isSaveDisabled: false,
    showMoreStatistics: false,
    activeFolio: true,
    panelFolios: false,
    row: 'radio-1',
    show: false,
    key: 'dsa#$%^dr8485jfs6dasuydsadr8485jf$%s6das&*-@#$%^&uy',
    // example
    chartLineData: {
      datasets: [{
        data: [346, 254, 435, 567, 234, 344, 767, 576, 786, 712, 543, 456],
        label: 'current',
        backgroundColor: '#304FFE',
        percent_change: [-13.88, 8.7, 10.93, 1.58, 3.72, 9.38, 20.78, -18.08, 2.93, 5.13, -3.37, 6.64]
      }],
      labels: ['2021-05-01', '2021-06-01', '2021-07-01', '2021-08-01', '2021-09-01', '2021-10-01', '2021-11-01', '2021-12-01', '2022-01-01', '2022-02-01', '2022-03-01', '2022-04-01']
    },
    // end expample
    posImages: ['', ''],
    panelPos: false,
    panelDocuments: false,
    loading: false,
    dialogInfoPos: false,
    dialogConfigPos: false,
    editableBasicInfoPos: {
      name: '',
      internal_id: ''
    },
    editable: {
      store_scheme: 1,
      pdf_copies: 1,
      store_path: '',
      sending_way: '',
      irs_store_path: '',
      id: 'pos_oiruehr7ywhfrdsds'
      // internal_id: 'store_34'
      // branch_id: 'bra_dsadr8485jfs6dasuyrt'
    },
    //  store_scheme: 'Base de datos',
      // name: 'Pos 24',
      // pdf_copies: '2',
      // store_path: 'C:\/DTE\PROCESO\/XML\/[RUTEmisor]_[TipoDTE]_[Folio].xml',
      // store_path_pdf: '',
      // sending_way: 'XML',
      // irs_store_path: 'C:\/DTE\/PROCESO',
      // id: 'pos_oiruehr7ywhfrdsds',
      // internal_id: 'store_34',
      // branch_id: 'bra_dsadr8485jfs6dasuyrt'
    pdfcopiesList: ['1', '2', '3', '4', '5'],
    sendingWayList: ['xml', 'txt'],
    statusBreadcrumbs: [
      {
        text: 'mdi-store',
        disabled: false,
        type: 'icon'
      },
      {
        text: 'Tienda',
        disabled: false
      },
      {
        text: 'Información del documento',
        disabled: true,
        type: 'name'
      }
    ],
    posBreadcrumbs: [
      {
        text: 'mdi-printer-pos',
        disabled: false,
        type: 'icon'
      },
      {
        text: 'Pos',
        disabled: false
      },
      {
        text: 'Información del documento',
        disabled: true,
        type: 'name'
      }
    ],
    activeFolioList: [
      {
        id: 'pos_dsadr8485jfs6dasuyr1',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        actualFolio: '3',
        restantes: '8',
        asignado: '09 may. 2022 12:31'
      }
    ],
    activeHeaders: [
      { text: 'Tipo de documento', value: 'documentTypes', class: 'font-weight-bold', sortable: false },
      { text: 'Desde', value: 'start', align: 'end', sortable: false },
      { text: 'Hasta', value: 'end', align: 'end', sortable: false },
      { text: 'Folio actual', value: 'actualFolio', align: 'end', sortable: false },
      { text: 'Restantes', value: 'restantes', align: 'end', sortable: false },
      { text: 'Asignado el', value: 'asignado', align: 'end', sortable: false }
    ],
    usedFolioList: [
      {
        id: 'pos_dsadr8485jfs6dasuyr1',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        asignado: '09 may. 2022 12:31'
      },
      {
        id: 'pos_dsadr8485jfs6dasuyr2',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        asignado: '09 may. 2022 12:31'
      },
      {
        id: 'pos_dsadr8485jfs6dasuyr3',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        asignado: '09 may. 2022 12:31'
      }
    ],
    usedHeaders: [
      { text: 'Tipo de documento', value: 'documentTypes', class: 'font-weight-bold', sortable: false },
      { text: 'Desde', value: 'start', align: 'end', sortable: false },
      { text: 'Hasta', value: 'end', align: 'end', sortable: false },
      { text: 'Asignado el', value: 'asignado', align: 'end', sortable: false }
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax',
    breadcrumbs: {
      main: 'POS ',
      children: [
        {
          text: 'Puntos de ventas',
          route: 'PosListCreate'
        }
      ]
    }
   }),
  computed: {
    ...mapState({
      count: state => state.documents.documentsCount,
      instance: state => state.pos.posDetail,
      posSequencesList: state => state.pos.posSequencesList,
      branchesList: state => state.branches.branchesList,
      documentsList: state => state.documents.documentsList
    }),
    currentHeader () {
      return this.activeFolio ? this.activeHeaders : this.usedHeaders
    },
    currentList () {
      return this.activeFolio ? this.activeFolioList : this.usedFolioList
    },
    currentBranch () {
      return this.branchesList?.find(b => b.id === this.instance.branch) ?? ''
    },
    cardMapDetail () {
      return [
        {
          title: 'Nombre',
          value: this.editable?.name ?? this.editable.custom_id ?? null
        },
        {
          title: 'Tienda',
          value: this.currentBranch?.name ?? '-',
          id: this.currentBranch.id
        },
        {
          title: 'ID',
          value: this.instance?.id ?? '-',
          isTooltip: true
        },
        {
          title: 'ID interno',
          value: this.instance?.custom_id ?? '-',
          isTooltip: true
        }
      ]
    }
  },
  watch: {
    panelDocuments (val) {
      if (val === 0) {
        this.getList()
      }
    },
    panelFolios (val) {
      this.activeHeaders[3].text = this.$t(`generals.${this.activeHeaders[3].text}`)
      // if (val === 0) this.getPosSequencesList()
    }
  },
  async created () {
    this.loadingPos = true
    await this.$store.dispatch('pos/RETRIEVE', {
      resource: 'pos',
      id: this.$route.params.id
    })
    this.loadingPos = false
    if (!this.branchesList.length) {
      this.$store.dispatch('branches/LIST', {
        resource: 'branches',
        query: this.instance.branch
      })
    }
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    async getList () {
      // if (!this.documentsList.length) {
        this.loading = true
        await this.$store.dispatch('documents/LIST', {
          resource: 'documents',
          query: {
            page_size: 6,
            posdocument__pos__id: this.instance.id
          }
        })
        this.loading = false
      // }
    },
    getPosSequencesList () {
      this.$store.dispatch('pos/LIST', {
        resource: 'pos/sequences',
        query: {
          pos: this.instance.id
        }
      })
    },
    setBasicInfoPos () {
      this.$v.editableBasicInfoPos.$touch()
      if (this.$v.editableBasicInfoPos.$invalid) {
        return false
      }
      const payload = {
        name: this.editableBasicInfoPos.name,
        internal_id: this.editableBasicInfoPos.internal_id
      }

      this.updatePos(payload)
    },
    setConfigPos () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
    },
    updatePos (payload = {}) {
      this.$store.dispatch('pos/UPDATE', {
        resource: 'pos',
        id: this.$route.params.id,
        payload: payload
      })
      .then(({data}) => {
        this.editable = data
        this.$dialog.message.info('Los datos del Pos fueron actualizados correctamente')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogInfoPos = false
      })
    },
    handleEditConfigPos () {
      this.dialogConfigPos = true
      this.editable = {...this.instance}
      const config = ['store_scheme', 'pdf_copies', 'store_path', 'sending_way', 'irs_store_path']
      Object.keys(this.editable).forEach(key => {
        if (!config.includes(key)) {
          delete this.editable[key]
        }
      })
    },
    handleEditBasicInfoPos () {
      this.dialogInfoPos = true
      this.editableBasicInfoPos = {
        name: this.instance.name,
        internal_id: this.instance.internal_id ?? 'store_34'
      }
      this.backupEditableBasicInfoPos = this.editableBasicInfoPos
    }
  },
  validations () {
     const editableBasicInfoPos = {
      internal_id: { required },
      name: { required }
    }
    const editable = {
      store_scheme: { required, maxLength: maxLength(100) },
      pdf_copies: { required },
      store_path: { required, maxLength: maxLength(64) },
      sending_way: { required, maxLength: maxLength(254) },
      irs_store_path: { required, maxLength: maxLength(16) }
    }
    return {
      editableBasicInfoPos,
      editable
    }
  }
}
</script>